import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Services from './Pages/Services';
import PlanTrip from './Pages/PlanTrip';
import Aura from './Pages/Aura';
import Insurance from './Pages/Insurance';
import GoodSamartian from './Pages/GoodSamartian';
import CarPooling from './Pages/CarPooling';
import AIDent from './Pages/AIDent';
import Finance from './Pages/Finance';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import FAQ from './Pages/FAQ';
import Carwash from './Pages/Carwash';
import Cancelation from './Pages/Cancelation';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';

function App() {
  return (
    <div>
      <Router >
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/Services' element={<Services/>}/>
          <Route path='Aura' element={<Aura/>}/>
          <Route path='/AIDent' element={<AIDent/>}/>
          <Route path='/CarPooling' element={<CarPooling/>}/>
          <Route path='/Carwash' element={<Carwash/>}/>
          <Route path='/Finance' element={<Finance/>}/>
          <Route path='/GoodSamartian' element={<GoodSamartian/>}/>
          <Route path='/Insurance' element={<Insurance/>}/>
          <Route path='/PlanTrip' element={<PlanTrip/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/About' element={<About/>}/>
          <Route path='/PrivacyPolicy' element={<Privacy/>}/>
          <Route path='/TermsAndConditions' element={<Terms/>}/>
          <Route path='/FAQ' element={<FAQ/>}/>
          <Route path='/CancelRefundPolicy' element={<Cancelation/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
