import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";

function FAQ() {
  const [activeTab, setActiveTab] = useState("Terms and Conditions");
  useEffect(() => {
    AOS.init();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div class="sect_cls">
        <header>
          <div class="container">
            <Header />
          </div>
        </header>
        <div class="container">
            <h4 className="companyName">Buzzler Technologies Private Limited</h4>
          <div className="tabs">
            <div
              className={`tab ${
                activeTab === "Terms and Conditions" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Terms and Conditions")}
            >
              Terms and Conditions
            </div>
            <div
              className={`tab ${
                activeTab === "privacy policy" ? "active" : ""
              }`}
              onClick={() => handleTabClick("privacy policy")}
            >
              Privacy Policy
            </div>
            <div
              className={`tab ${
                activeTab === "cancellation and Refund Policy" ? "active" : ""
              }`}
              onClick={() => handleTabClick("cancellation and Refund Policy")}
            >
              Cancellation and Refund Policy
            </div>
            <div
              className={`tab ${activeTab === "FAQ" ? "active" : ""}`}
              onClick={() => handleTabClick("FAQ")}
            >
              FAQ's
            </div>
          </div>
        </div>
        {activeTab === "FAQ" ? (
          <section>
            <div class="container mb-5">
              <h1 className="faq-text1">FAQ's</h1>
              <div class="container">
                <div class="service_background shadow mt-4">
                  <div class="accordion-container">
                    <div className="accordion" id="accordionExample">
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{ color: "#F9A738" }}
                          >
                            <p>How to add multiple Vehicles on my account?</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              You can add only one vehicle as of now, we will
                              provide this feature in next updates.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            style={{ color: "#F9A738" }}
                          >
                            Is my Data is Secure here?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Our system is designed one way communication to
                              all aspects, so we assure on this for 100%, we
                              will not exchange any data to anyone including
                              Vechical number or state as well.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{ color: "#F9A738" }}
                          >
                            Can I refer my friends on this?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We are happy to help all, you can refer them in
                              very simple model, Just type REFER on our BOT, it
                              will allow you select the contacts from your
                              contact list, so you can easily send to welcome
                              message.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            style={{ color: "#F9A738" }}
                          >
                            How to become a Paid Super Heros?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We have a vast free laser network in India, any
                              how we are still looking for Super Hero’s on paid
                              version as well, we will give great reward on
                              immediate response on incident, you can simple
                              register our network and show interest, we will
                              call back you and register.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            style={{ color: "#F9A738" }}
                          >
                            I want to sell your Product in market?
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If you want to connect with us through API, you
                              can send a email to hello@balert.in we will
                              contact you back and make sure your integrations
                              will happen asap. And we are offering best price
                              for wholesalers, If you want to work as a TL ,
                              Agent , Send a mail with your team size we will
                              give on our portal.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            style={{ color: "#F9A738" }}
                          >
                            How can I report Incident?
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Any register user can report this; you no need to
                              register as a super hero. Just Click on Emergency
                              button on Our BOT, or you simple type EMERGENCY on
                              it, it will 3-4 Questions, like Please take the
                              images to convince the Audit team and make sure
                              genuine cases only populate. Like Number plate,
                              Damage, Situation, etc..
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            style={{ color: "#F9A738" }}
                          >
                            Will you provide Ambulance services only for
                            accidents or any normal cases also?
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes for all cases. We are market place for
                              Ambulance services in Urban areas, we have tied up
                              with multiple companies, on one click lead will be
                              distributed to multiple private operators and they
                              will assist you for same. Not a Free service.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            style={{ color: "#F9A738" }}
                          >
                            What about On Road assistance 24/7 in urban areas?
                          </button>
                        </h2>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We are in process to connect with some companies
                              on same; in future update we will bring this
                              service also available in our system. We are
                              trying to create aggregator platform all services.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                            style={{ color: "#F9A738" }}
                          >
                            Only Super Hero’s get paid on this Incident Report?
                          </button>
                        </h2>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, It’s not about the money. It’s our
                              responsibility to Inform to their loving people.
                              Any how we will not disappoint you. We will give
                              superior value for it. No worries about it.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            style={{ color: "#F9A738" }}
                          >
                            Why everything on Chat box, No Calls?
                          </button>
                        </h2>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, Only on chat discussion. As we have emergency
                              services.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            style={{ color: "#F9A738" }}
                          >
                            How does B-ALERT's accident and anomaly alert system
                            work to monitor vehicles and personal well-being?
                          </button>
                        </h2>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              B-ALERT's accident and anomaly alert system
                              utilizes advanced sensors and algorithms to
                              monitor various vehicle parameters and the
                              driver's well-being. It can detect anomalies like
                              sudden braking, collisions, or accidents. When an
                              incident occurs, the system immediately alerts the
                              user's designated contact list through SMS or
                              other communication channels.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            style={{ color: "#F9A738" }}
                          >
                            What are the benefits of using B-ALERT technology
                            while driving in India?
                          </button>
                        </h2>
                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Using B-ALERT technology provides several benefits
                              for drivers in India. It offers a safety net by
                              promptly informing loved ones about any
                              emergencies on the road. This quick alert system
                              can expedite help and support in case of
                              accidents. B-ALERT's ease of access and
                              user-friendly interface make it a convenient
                              option for all drivers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                            style={{ color: "#F9A738" }}
                          >
                            Does B-ALERT offer any additional features or
                            services apart from accident alerts?
                          </button>
                        </h2>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, B-ALERT offers additional features to enhance
                              user experience and safety. These may include
                              real-time vehicle tracking, geofencing
                              capabilities, and personalized driving behavior
                              analysis to improve driving habits.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            style={{ color: "#F9A738" }}
                          >
                            Can you explain how to register for B-ALERT's system
                            and access the discount coupons?
                          </button>
                        </h2>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Registering with B-ALERT requires only a mobile
                              phone and an internet connection. Once you sign
                              up, you can enter your email address to receive
                              discount coupons. These coupons can save new
                              customers anywhere between 20 to 40 percent on
                              B-ALERT's services.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFifteen"
                            aria-expanded="false"
                            aria-controls="collapseFifteen"
                            style={{ color: "#F9A738" }}
                          >
                            How quickly can B-ALERT's system alert my designated
                            contacts in case of an accident or anomaly?
                          </button>
                        </h2>
                        <div
                          id="collapseFifteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              B-ALERT's system is designed for immediate
                              response. The system promptly alerts the user's
                              designated contact list when it detects an
                              accident or anomaly. This ensures that your loved
                              ones are informed without delay.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                            style={{ color: "#F9A738" }}
                          >
                            What certifications does B-ALERT hold, and how does
                            it contribute to the company's credibility?
                          </button>
                        </h2>
                        <div
                          id="collapseSixteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              B-ALERT is certified by various business partners,
                              which adds to the company's credibility and
                              trustworthiness. These certifications demonstrate
                              that B-ALERT meets industry standards and
                              regulations, providing users with confidence in
                              the service's reliability.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="collapseSeventeen"
                            style={{ color: "#F9A738" }}
                          >
                            Is B-ALERT's service limited to particular vehicle
                            types, or can it be used with any vehicle?
                          </button>
                        </h2>
                        <div
                          id="collapseSeventeen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              B-ALERT's service is compatible with any vehicle,
                              whether a car, motorcycle, or another motorized
                              vehicle. As long as you have a mobile phone and an
                              internet connection, you can use B-ALERT's system
                              irrespective of your vehicle.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEighteen"
                            aria-expanded="false"
                            aria-controls="collapseEighteen"
                            style={{ color: "#F9A738" }}
                          >
                            Can I use B-ALERT even if I don't have a WhatsApp
                            account? Are there alternative communication
                            methods?
                          </button>
                        </h2>
                        <div
                          id="collapseEighteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, you can use B-ALERT without a WhatsApp
                              account. While WhatsApp is one of the
                              communication methods, B-ALERT likely offers other
                              options, such as Telegram, Web, Insta, Messager ,
                              Mobile app, to ensure users can receive alerts and
                              notifications regardless of their preferred
                              communication platform.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNineteen"
                            aria-expanded="false"
                            aria-controls="collapseNineteen"
                            style={{ color: "#F9A738" }}
                          >
                            What sets B-ALERT apart from other similar services
                            in India that claim to provide vehicle monitoring?
                          </button>
                        </h2>
                        <div
                          id="collapseNineteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              B-ALERT stands out due to its comprehensive
                              accident and anomaly alert system, prompt response
                              times, easy accessibility, and personalized
                              customer support. Its discounts for new customers
                              and certification from trusted business partners
                              also add to its appeal and credibility.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwenty"
                            aria-expanded="false"
                            aria-controls="collapseTwenty"
                            style={{ color: "#F9A738" }}
                          >
                            Does B-ALERT offer personalized support for users if
                            they encounter issues or have specific inquiries?
                          </button>
                        </h2>
                        <div
                          id="collapseTwenty"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes, B-ALERT provides personalized support for its
                              users. New customers can access a dedicated server
                              with agents who can address inquiries, assist with
                              registration, and offer assistance when needed.
                              This ensures a smooth user experience and quick
                              resolution of concerns or inquiries.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : activeTab === "Terms and Conditions" ? (
          <section>
            <div class="container">
              <div class="container mb-5">
                <h1 className="faq-text1">Terms and Conditions</h1>
                <div class="service_background shadow mt-5">
                  <div class="accordion-container">
                    <div className="accordion" id="accordionExample">
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            style={{ color: "#F9A738" }}
                          >
                            <p>General Terms</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              By accessing and placing an order with balert, you
                              confirm that you are in agreement with and bound
                              by the terms of service contained in the Terms &
                              Conditions outlined below. These terms apply to
                              the entire website and any email or other type of
                              communication between you and balert.
                            </p>
                            <p>
                              Under no circumstances shall balert team be liable
                              for any direct, indirect, special, incidental or
                              consequential damages, including, but not limited
                              to, loss of data or profit, arising out of the
                              use, or the inability to use, the materials on
                              this site, even if balert team or an authorized
                              representative has been advised of the possibility
                              of such damages. If your use of materials from
                              this site results in the need for servicing,
                              repair or correction of equipment or data, you
                              assume any costs thereof.
                            </p>
                            <p>
                              balert will not be responsible for any outcome
                              that may occur during the course of usage of our
                              resources. We reserve the rights to change prices
                              and revise the resources usage policy in any
                              moment.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            style={{ color: "#F9A738" }}
                          >
                            <p>License</p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert grants you a revocable, non-exclusive,
                              non-transferable, limited license to download,
                              install and use the website strictly in accordance
                              with the terms of this Agreement.
                            </p>
                            <p>
                              These Terms & Conditions are a contract between
                              you and balert (referred to in these Terms &
                              Conditions as "balert", "us", "we" or "our"), the
                              provider of the balert website and the services
                              accessible from the balert website (which are
                              collectively referred to in these Terms &
                              Conditions as the "balert Service").
                            </p>
                            <p>
                              You are agreeing to be bound by these Terms &
                              Conditions. If you do not agree to these Terms &
                              Conditions, please do not use the balert Service.
                              In these Terms & Conditions, "you" refers both to
                              you as an individual and to the entity you
                              represent. If you violate any of these Terms &
                              Conditions, we reserve the right to cancel your
                              account or block access to your account without
                              notice.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Definitions and key terms</p>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              To help explain things as clearly as possible in
                              this Terms & Conditions, every time any of these
                              terms are referenced, are strictly defined as:
                            </p>
                            <li>
                              Cookie: small amount of data generated by a
                              website and saved by your web browser. It is used
                              to identify your browser, provide analytics,
                              remember information about you such as your
                              language preference or login information.
                            </li>
                            <li>
                              Company: when this terms mention “Company,” “we,”
                              “us,” or “our,” it refers to BUZZLER TECHNOLOGIES
                              PRIVATE LIMITED, (Flat No-682 Station No 14,
                              Alience Space Station, Tellapur, Ramachandrapuram,
                              Medak-502032, Telangana), that is responsible for
                              your information under this Terms & Conditions.
                            </li>
                            <li>
                              Country: where balert or the owners/founders of
                              balert are based, in this case is India
                            </li>
                            <li>
                              Device: any internet connected device such as a
                              phone, tablet, computer or any other device that
                              can be used to visit balert and use the services.
                            </li>
                            <li>
                              Service: refers to the service provided by balert
                              as described in the relative terms (if available)
                              and on this platform.
                            </li>
                            <li>
                              Third-party service: refers to advertisers,
                              contest sponsors, promotional and marketing
                              partners, and others who provide our content or
                              whose products or services we think may interest
                              you.
                            </li>
                            <li>
                              Website: balert’s site, which can be accessed via
                              this URL: www.balert.in
                            </li>
                            <li>
                              You: a person or entity that is registered with
                              balert to use the Services.
                            </li>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Restrictions</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              You agree not to, and you will not permit others
                              to:
                            </p>
                            <ul>
                              <li>
                                License, sell, rent, lease, assign, distribute,
                                transmit, host, outsource, disclose or otherwise
                                commercially exploit the website or make the
                                platform available to any third party.
                              </li>
                              <li>
                                Modify, make derivative works of, disassemble,
                                decrypt, reverse compile or reverse engineer any
                                part of the website.
                              </li>
                              <li>
                                Remove, alter or obscure any proprietary notice
                                (including any notice of copyright or trademark)
                                of balert or its affiliates, partners, suppliers
                                or the licensors of the website.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Payment</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If you register to any of our recurring payment
                              plans, you agree to pay all fees or charges to
                              your account for the Service in accordance with
                              the fees, charges and billing terms in effect at
                              the time that each fee or charge is due and
                              payable. Unless otherwise indicated in an order
                              form, you must provide balert with a valid credit
                              card (Visa, MasterCard, or any other issuer
                              accepted by us) (“Payment Provider”) as a
                              condition to signing up for the Premium plan. Your
                              Payment Provider agreement governs your use of the
                              designated credit card account, and you must refer
                              to that agreement and not these Terms to determine
                              your rights and liabilities with respect to your
                              Payment Provider. By providing balert with your
                              credit card number and associated payment
                              information, you agree that balert is authorized
                              to verify information immediately, and
                              subsequently invoice your account for all fees and
                              charges due and payable to balert hereunder and
                              that no additional notice or consent is required.
                              You agree to immediately notify balert of any
                              change in your billing address or the credit card
                              used for payment hereunder. balert reserves the
                              right at any time to change its prices and billing
                              methods, either immediately upon posting on our
                              Site or by e-mail delivery to your organization’s
                              administrator(s).
                            </p>
                            <p>
                              Any attorney fees, court costs, or other costs
                              incurred in collection of delinquent undisputed
                              amounts shall be the responsibility of and paid
                              for by you.
                            </p>
                            <p>
                              No contract will exist between you and balert for
                              the Service until balert accepts your order by a
                              confirmatory e-mail, SMS/MMS message, or other
                              appropriate means of communication.
                            </p>
                            <p>
                              You are responsible for any third-party fees that
                              you may incur when using the Service.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Your Suggestions</p>
                          </button>
                        </h2>
                        <div
                          id="collapseSix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Any feedback, comments, ideas, improvements or
                              suggestions (collectively, "Suggestions") provided
                              by you to balert with respect to the website shall
                              remain the sole and exclusive property of balert.
                            </p>
                            <p>
                              balert shall be free to use, copy, modify,
                              publish, or redistribute the Suggestions for any
                              purpose and in any way without any credit or any
                              compensation to you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Your Consent</p>
                          </button>
                        </h2>
                        <div
                          id="collapseSeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We've updated our Terms & Conditions to provide
                              you with complete transparency into what is being
                              set when you visit our site and how it's being
                              used. By using our website, registering an
                              account, or making a purchase, you hereby consent
                              to our Terms & Conditions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Links to Other Websites</p>
                          </button>
                        </h2>
                        <div
                          id="collapseEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              This Terms & Conditions applies only to the
                              Services. The Services may contain links to other
                              websites not operated or controlled by balert. We
                              are not responsible for the content, accuracy or
                              opinions expressed in such websites, and such
                              websites are not investigated, monitored or
                              checked for accuracy or completeness by us. Please
                              remember that when you use a link to go from the
                              Services to another website, our Terms &
                              Conditions are no longer in effect. Your browsing
                              and interaction on any other website, including
                              those that have a link on our platform, is subject
                              to that website’s own rules and policies. Such
                              third parties may use their own cookies or other
                              methods to collect information about you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Cookies</p>
                          </button>
                        </h2>
                        <div
                          id="collapseNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert uses "Cookies" to identify the areas of our
                              website that you have visited. A Cookie is a small
                              piece of data stored on your computer or mobile
                              device by your web browser. We use Cookies to
                              enhance the performance and functionality of our
                              website but are non-essential to their use.
                              However, without these cookies, certain
                              functionality like videos may become unavailable
                              or you would be required to enter your login
                              details every time you visit the website as we
                              would not be able to remember that you had logged
                              in previously. Most web browsers can be set to
                              disable the use of Cookies. However, if you
                              disable Cookies, you may not be able to access
                              functionality on our website correctly or at all.
                              We never place Personally Identifiable Information
                              in Cookies.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseTen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Changes To Our Terms & Conditions</p>
                          </button>
                        </h2>
                        <div
                          id="collapseTen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              You acknowledge and agree that balert may stop
                              (permanently or temporarily) providing the Service
                              (or any features within the Service) to you or to
                              users generally at balert’s sole discretion,
                              without prior notice to you. You may stop using
                              the Service at any time. You do not need to
                              specifically inform balert when you stop using the
                              Service. You acknowledge and agree that if balert
                              disables access to your account, you may be
                              prevented from accessing the Service, your account
                              details or any files or other materials which is
                              contained in your account.
                            </p>
                            <p>
                              If we decide to change our Terms & Conditions, we
                              will post those changes on this page, and/or
                              update the Terms & Conditions modification date
                              below.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Modifications to Our website</p>
                          </button>
                        </h2>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert reserves the right to modify, suspend or
                              discontinue, temporarily or permanently, the
                              website or any service to which it connects, with
                              or without notice and without liability to you.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Updates to Our website</p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert may from time to time provide enhancements
                              or improvements to the features/ functionality of
                              the website, which may include patches, bug fixes,
                              updates, upgrades and other modifications
                              ("Updates").
                            </p>
                            <p>
                              Updates may modify or delete certain features
                              and/or functionalities of the website. You agree
                              that balert has no obligation to (i) provide any
                              Updates, or (ii) continue to provide or enable any
                              particular features and/or functionalities of the
                              website to you.
                            </p>
                            <p>
                              You further agree that all Updates will be (i)
                              deemed to constitute an integral part of the
                              website, and (ii) subject to the terms and
                              conditions of this Agreement.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Third-Party Services</p>
                          </button>
                        </h2>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We may display, include or make available
                              third-party content (including data, information,
                              applications and other products services) or
                              provide links to third-party websites or services
                              ("Third- Party Services").{" "}
                            </p>
                            <p>
                              You acknowledge and agree that balert shall not be
                              responsible for any Third-Party Services,
                              including their accuracy, completeness,
                              timeliness, validity, copyright compliance,
                              legality, decency, quality or any other aspect
                              thereof. balert does not assume and shall not have
                              any liability or responsibility to you or any
                              other person or entity for any Third-Party
                              Services.
                            </p>
                            <p>
                              Third-Party Services and links thereto are
                              provided solely as a convenience to you and you
                              access and use them entirely at your own risk and
                              subject to such third parties' terms and
                              conditions.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Term and Termination</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              This Agreement shall remain in effect until
                              terminated by you or balert.
                            </p>
                            <p>
                              balert may, in its sole discretion, at any time
                              and for any or no reason, suspend or terminate
                              this Agreement with or without prior notice.
                            </p>
                            <p>
                              This Agreement will terminate immediately, without
                              prior notice from balert, in the event that you
                              fail to comply with any provision of this
                              Agreement. You may also terminate this Agreement
                              by deleting the website and all copies thereof
                              from your computer.
                            </p>
                            <p>
                              Upon termination of this Agreement, you shall
                              cease all use of the website and delete all copies
                              of the website from your computer.
                            </p>
                            <p>
                              Termination of this Agreement will not limit any
                              of balert's rights or remedies at law or in equity
                              in case of breach by you (during the term of this
                              Agreement) of any of your obligations under the
                              present Agreement.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFifteen"
                            aria-expanded="false"
                            aria-controls="collapseFifteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Copyright Infringement Notice</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFifteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If you are a copyright owner or such ownerâ's
                              agent and believe any material on our website
                              constitutes an infringement on your copyright,
                              please contact us setting forth the following
                              information: (a) a physical or electronic
                              signature of the copyright owner or a person
                              authorized to act on his behalf; (b)
                              identification of the material that is claimed to
                              be infringing; (c) your contact information,
                              including your address, telephone number, and an
                              email; (d) a statement by you that you have a good
                              faith belief that use of the material is not
                              authorized by the copyright owners; and (e) the a
                              statement that the information in the notification
                              is accurate, and, under penalty of perjury you are
                              authorized to act on behalf of the owner.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Indemnification</p>
                          </button>
                        </h2>
                        <div
                          id="collapseSixteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              The website is provided to you "AS IS" and "AS
                              AVAILABLE" and with all faults and defects without
                              warranty of any kind. To the maximum extent
                              permitted under applicable law, balert, on its own
                              behalf and on behalf of its affiliates and its and
                              their respective licensors and service providers,
                              expressly disclaims all warranties, whether
                              express, implied, statutory or otherwise, with
                              respect to the website, including all implied
                              warranties of merchantability, fitness for a
                              particular purpose, title and non-infringement,
                              and warranties that may arise out of course of
                              dealing, course of performance, usage or trade
                              practice. Without limitation to the foregoing,
                              balert provides no warranty or undertaking, and
                              makes no representation of any kind that the
                              website will meet your requirements, achieve any
                              intended results, be compatible or work with any
                              other software, websites, systems or services,
                              operate without interruption, meet any performance
                              or reliability standards or be error free or that
                              any errors or defects can or will be corrected.
                            </p>
                            <p>
                              Without limiting the foregoing, neither balert nor
                              any balert's provider makes any representation or
                              warranty of any kind, express or implied: (i) as
                              to the operation or availability of the website,
                              or the information, content, and materials or
                              products included thereon; (ii) that the website
                              will be uninterrupted or error-free; (iii) as to
                              the accuracy, reliability, or currency of any
                              information or content provided through the
                              website; or (iv) that the website, its servers,
                              the content, or e-mails sent from or on behalf of
                              balert are free of viruses, scripts, trojan
                              horses, worms, malware, timebombs or other harmful
                              components.
                            </p>
                            <p>
                              Some jurisdictions do not allow the exclusion of
                              or limitations on implied warranties or the
                              limitations on the applicable statutory rights of
                              a consumer, so some or all of the above exclusions
                              and limitations may not apply to you.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="collapseSeventeen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>No Warranties</p>
                          </button>
                        </h2>
                        <div
                          id="collapseSeventeen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              The website is provided to you "AS IS" and "AS
                              AVAILABLE" and with all faults and defects without
                              warranty of any kind. To the maximum extent
                              permitted under applicable law, balert, on its own
                              behalf and on behalf of its affiliates and its and
                              their respective licensors and service providers,
                              expressly disclaims all warranties, whether
                              express, implied, statutory or otherwise, with
                              respect to the website, including all implied
                              warranties of merchantability, fitness for a
                              particular purpose, title and non-infringement,
                              and warranties that may arise out of course of
                              dealing, course of performance, usage or trade
                              practice. Without limitation to the foregoing,
                              balert provides no warranty or undertaking, and
                              makes no representation of any kind that the
                              website will meet your requirements, achieve any
                              intended results, be compatible or work with any
                              other software, websites, systems or services,
                              operate without interruption, meet any performance
                              or reliability standards or be error free or that
                              any errors or defects can or will be corrected.
                            </p>
                            <p>
                              Without limiting the foregoing, neither balert nor
                              any balert's provider makes any representation or
                              warranty of any kind, express or implied: (i) as
                              to the operation or availability of the website,
                              or the information, content, and materials or
                              products included thereon; (ii) that the website
                              will be uninterrupted or error-free; (iii) as to
                              the accuracy, reliability, or currency of any
                              information or content provided through the
                              website; or (iv) that the website, its servers,
                              the content, or e-mails sent from or on behalf of
                              balert are free of viruses, scripts, trojan
                              horses, worms, malware, timebombs or other harmful
                              components.
                            </p>
                            <p>
                              Some jurisdictions do not allow the exclusion of
                              or limitations on implied warranties or the
                              limitations on the applicable statutory rights of
                              a consumer, so some or all of the above exclusions
                              and limitations may not apply to you.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEighteen"
                            aria-expanded="false"
                            aria-controls="collapseEighteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Limitation of Liability</p>
                          </button>
                        </h2>
                        <div
                          id="collapseEighteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Notwithstanding any damages that you might incur,
                              the entire liability of balert and any of its
                              suppliers under any provision of this Agreement
                              and your exclusive remedy for all of the foregoing
                              shall be limited to the amount actually paid by
                              you for the website.
                            </p>
                            <p>
                              To the maximum extent permitted by applicable law,
                              in no event shall balert or its suppliers be
                              liable for any special, incidental, indirect, or
                              consequential damages whatsoever (including, but
                              not limited to, damages for loss of profits, for
                              loss of data or other information, for business
                              interruption, for personal injury, for loss of
                              privacy arising out of or in any way related to
                              the use of or inability to use the website,
                              third-party software and/or third-party hardware
                              used with the website, or otherwise in connection
                              with any provision of this Agreement), even if
                              balert or any supplier has been advised of the
                              possibility of such damages and even if the remedy
                              fails of its essential purpose.
                            </p>
                            <p>
                              Some states/jurisdictions do not allow the
                              exclusion or limitation of incidental or
                              consequential damages, so the above limitation or
                              exclusion may not apply to you.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseNineteen"
                            aria-expanded="false"
                            aria-controls="collapseNineteen"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Severability</p>
                          </button>
                        </h2>
                        <div
                          id="collapseNineteen"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If any provision of this Agreement is held to be
                              unenforceable or invalid, such provision will be
                              changed and interpreted to accomplish the
                              objectives of such provision to the greatest
                              extent possible under applicable law and the
                              remaining provisions will continue in full force
                              and effect.
                            </p>
                            <p>
                              This Agreement, together with the Privacy Policy
                              and any other legal notices published by balert on
                              the Services, shall constitute the entire
                              agreement between you and balert concerning the
                              Services. If any provision of this Agreement is
                              deemed invalid by a court of competent
                              jurisdiction, the invalidity of such provision
                              shall not affect the validity of the remaining
                              provisions of this Agreement, which shall remain
                              in full force and effect. No waiver of any term of
                              this Agreement shall be deemed a further or
                              continuing waiver of such term or any other term,
                              and balert’s failure to assert any right or
                              provision under this Agreement shall not
                              constitute a waiver of such right or provision.
                              YOU AND balert AGREE THAT ANY CAUSE OF ACTION
                              ARISING OUT OF OR RELATED TO THE SERVICES MUST
                              COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
                              ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS
                              PERMANENTLY BARRED.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Twenty"
                            aria-expanded="false"
                            aria-controls="Twenty"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Waiver</p>
                          </button>
                        </h2>
                        <div
                          id="Twenty"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Except as provided herein, the failure to exercise
                              a right or to require performance of an obligation
                              under this Agreement shall not effect a party's
                              ability to exercise such right or require such
                              performance at any time thereafter nor shall be
                              the waiver of a breach constitute waiver of any
                              subsequent breach.
                            </p>
                            <p>
                              No failure to exercise, and no delay in
                              exercising, on the part of either party, any right
                              or any power under this Agreement shall operate as
                              a waiver of that right or power. Nor shall any
                              single or partial exercise of any right or power
                              under this Agreement preclude further exercise of
                              that or any other right granted herein. In the
                              event of a conflict between this Agreement and any
                              applicable purchase or other terms, the terms of
                              this Agreement shall govern.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyOne"
                            aria-expanded="false"
                            aria-controls="TwentyOne"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Amendments to this Agreement</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert reserves the right, at its sole discretion,
                              to modify or replace this Agreement at any time.
                              If a revision is material we will provide at least
                              30 days' notice prior to any new terms taking
                              effect. What constitutes a material change will be
                              determined at our sole discretion.
                            </p>
                            <p>
                              By continuing to access or use our website after
                              any revisions become effective, you agree to be
                              bound by the revised terms. If you do not agree to
                              the new terms, you are no longer authorized to use
                              balert.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyTwo"
                            aria-expanded="false"
                            aria-controls="TwentyTwo"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Entire Agreement</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              The Agreement constitutes the entire agreement
                              between you and balert regarding your use of the
                              website and supersedes all prior and
                              contemporaneous written or oral agreements between
                              you and balert.
                            </p>
                            <p>
                              You may be subject to additional terms and
                              conditions that apply when you use or purchase
                              other balert's services, which balert will provide
                              to you at the time of such use or purchase.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyThree"
                            aria-expanded="false"
                            aria-controls="TwentyThree"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Updates to Our Terms</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              We may change our Service and policies, and we may
                              need to make changes to these Terms so that they
                              accurately reflect our Service and policies.
                              Unless otherwise required by law, we will notify
                              you (for example, through our Service) before we
                              make changes to these Terms and give you an
                              opportunity to review them before they go into
                              effect. Then, if you continue to use the Service,
                              you will be bound by the updated Terms. If you do
                              not want to agree to these or any updated Terms,
                              you can delete your account.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyFour"
                            aria-expanded="false"
                            aria-controls="TwentyFour"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Intellectual Property</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyFour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              The website and its entire contents, features and
                              functionality (including but not limited to all
                              information, software, text, displays, images,
                              video and audio, and the design, selection and
                              arrangement thereof), are owned by balert, its
                              licensors or other providers of such material and
                              are protected by India and international
                              copyright, trademark, patent, trade secret and
                              other intellectual property or proprietary rights
                              laws. The material may not be copied, modified,
                              reproduced, downloaded or distributed in any way,
                              in whole or in part, without the express prior
                              written permission of balert, unless and except as
                              is expressly provided in these Terms & Conditions.
                              Any unauthorized use of the material is
                              prohibited.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyFive"
                            aria-expanded="false"
                            aria-controls="TwentyFive"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Agreement to Arbitrate</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              This section applies to any dispute EXCEPT IT
                              DOESN'T INCLUDE A DISPUTE RELATING TO CLAIMS FOR
                              INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE
                              ENFORCEMENT OR VALIDITY OF YOUR OR balert's
                              INTELLECTUAL PROPERTY RIGHTS. The term “dispute”
                              means any dispute, action, or other controversy
                              between you and balert concerning the Services or
                              this agreement, whether in contract, warranty,
                              tort, statute, regulation, ordinance, or any other
                              legal or equitable basis. “Dispute” will be given
                              the broadest possible meaning allowable under law.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentySix"
                            aria-expanded="false"
                            aria-controls="TwentySix"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Notice of Dispute</p>
                          </button>
                        </h2>
                        <div
                          id="TwentySix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              In the event of a dispute, you or balert must give
                              the other a Notice of Dispute, which is a written
                              statement that sets forth the name, address, and
                              contact information of the party giving it, the
                              facts giving rise to the dispute, and the relief
                              requested. You must send any Notice of Dispute via
                              email to: hello@buzzler.in. balert will send any
                              Notice of Dispute to you by mail to your address
                              if we have it, or otherwise to your email address.
                              You and balert will attempt to resolve any dispute
                              through informal negotiation within sixty (60)
                              days from the date the Notice of Dispute is sent.
                              After sixty (60) days, you or balert may commence
                              arbitration.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentySeven"
                            aria-expanded="false"
                            aria-controls="TwentySeven"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Binding Arbitration</p>
                          </button>
                        </h2>
                        <div
                          id="TwentySeven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If you and balert don’t resolve any dispute by
                              informal negotiation, any other effort to resolve
                              the dispute will be conducted exclusively by
                              binding arbitration as described in this section.
                              You are giving up the right to litigate (or
                              participate in as a party or class member) all
                              disputes in court before a judge or jury. The
                              dispute shall be settled by binding arbitration in
                              accordance with the commercial arbitration rules
                              of the American Arbitration Association. Either
                              party may seek any interim or preliminary
                              injunctive relief from any court of competent
                              jurisdiction, as necessary to protect the party’s
                              rights or property pending the completion of
                              arbitration. Any and all legal, accounting, and
                              other costs, fees, and expenses incurred by the
                              prevailing party shall be borne by the
                              non-prevailing party.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyEight"
                            aria-expanded="false"
                            aria-controls="TwentyEight"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Submissions and Privacy</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyEight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              In the event that you submit or post any ideas,
                              creative suggestions, designs, photographs,
                              information, advertisements, data or proposals,
                              including ideas for new or improved products,
                              services, features, technologies or promotions,
                              you expressly agree that such submissions will
                              automatically be treated as non-confidential and
                              non-proprietary and will become the sole property
                              of balert without any compensation or credit to
                              you whatsoever. balert and its affiliates shall
                              have no obligations with respect to such
                              submissions or posts and may use the ideas
                              contained in such submissions or posts for any
                              purposes in any medium in perpetuity, including,
                              but not limited to, developing, manufacturing, and
                              marketing products and services using such ideas.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#TwentyNine"
                            aria-expanded="false"
                            aria-controls="TwentyNine"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Promotions</p>
                          </button>
                        </h2>
                        <div
                          id="TwentyNine"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert may, from time to time, include contests,
                              promotions, sweepstakes, or other activities
                              (“Promotions”) that require you to submit material
                              or information concerning yourself. Please note
                              that all Promotions may be governed by separate
                              rules that may contain certain eligibility
                              requirements, such as restrictions as to age and
                              geographic location. You are responsible to read
                              all Promotions rules to determine whether or not
                              you are eligible to participate. If you enter any
                              Promotion, you agree to abide by and to comply
                              with all Promotions Rules.
                            </p>
                            <p>
                              Additional terms and conditions may apply to
                              purchases of goods or services on or through the
                              Services, which terms and conditions are made a
                              part of this Agreement by this reference.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Thirty"
                            aria-expanded="false"
                            aria-controls="Thirty"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Typographical Errors</p>
                          </button>
                        </h2>
                        <div
                          id="Thirty"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              In the event a product and/or service is listed at
                              an incorrect price or with incorrect information
                              due to typographical error, we shall have the
                              right to refuse or cancel any orders placed for
                              the product and/or service listed at the incorrect
                              price. We shall have the right to refuse or cancel
                              any such order whether or not the order has been
                              confirmed and your credit card charged. If your
                              credit card has already been charged for the
                              purchase and your order is canceled, we shall
                              immediately issue a credit to your credit card
                              account or other payment account in the amount of
                              the charge.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#ThirtyOne"
                            aria-expanded="false"
                            aria-controls="ThirtyOne"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Miscellaneous</p>
                          </button>
                        </h2>
                        <div
                          id="ThirtyOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              If for any reason a court of competent
                              jurisdiction finds any provision or portion of
                              these Terms & Conditions to be unenforceable, the
                              remainder of these Terms & Conditions will
                              continue in full force and effect. Any waiver of
                              any provision of these Terms & Conditions will be
                              effective only if in writing and signed by an
                              authorized representative of balert. balert will
                              be entitled to injunctive or other equitable
                              relief (without the obligations of posting any
                              bond or surety) in the event of any breach or
                              anticipatory breach by you. balert operates and
                              controls the balert Service from its offices in
                              India. The Service is not intended for
                              distribution to or use by any person or entity in
                              any jurisdiction or country where such
                              distribution or use would be contrary to law or
                              regulation. Accordingly, those persons who choose
                              to access the balert Service from other locations
                              do so on their own initiative and are solely
                              responsible for compliance with local laws, if and
                              to the extent local laws are applicable. These
                              Terms & Conditions (which include and incorporate
                              the balert Privacy Policy) contains the entire
                              understanding, and supersedes all prior
                              understandings, between you and balert concerning
                              its subject matter, and cannot be changed or
                              modified by you. The section headings used in this
                              Agreement are for convenience only and will not be
                              given any legal import.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#ThirtyTwo"
                            aria-expanded="false"
                            aria-controls="ThirtyTwo"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Disclaimer</p>
                          </button>
                        </h2>
                        <div
                          id="ThirtyTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              balert is not responsible for any content, code or
                              any other imprecision.
                            </p>
                            <p>
                              balert does not provide warranties or guarantees.
                            </p>
                            <p>
                              In no event shall balert be liable for any
                              special, direct, indirect, consequential, or
                              incidental damages or any damages whatsoever,
                              whether in an action of contract, negligence or
                              other tort, arising out of or in connection with
                              the use of the Service or the contents of the
                              Service. The Company reserves the right to make
                              additions, deletions, or modifications to the
                              contents on the Service at any time without prior
                              notice.
                            </p>
                            <p>
                              The balert Service and its contents are provided
                              "as is" and "as available" without any warranty or
                              representations of any kind, whether express or
                              implied. balert is a distributor and not a
                              publisher of the content supplied by third
                              parties; as such, balert exercises no editorial
                              control over such content and makes no warranty or
                              representation as to the accuracy, reliability or
                              currency of any information, content, service or
                              merchandise provided through or accessible via the
                              balert Service. Without limiting the foregoing,
                              balert specifically disclaims all warranties and
                              representations in any content transmitted on or
                              in connection with the balert Service or on sites
                              that may appear as links on the balert Service, or
                              in the products provided as a part of, or
                              otherwise in connection with, the balert Service,
                              including without limitation any warranties of
                              merchantability, fitness for a particular purpose
                              or non-infringement of third party rights. No oral
                              advice or written information given by balert or
                              any of its affiliates, employees, officers,
                              directors, agents, or the like will create a
                              warranty. Price and availability information is
                              subject to change without notice. Without limiting
                              the foregoing, balert does not warrant that the
                              balert Service will be uninterrupted, uncorrupted,
                              timely, or error-free.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion-item"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#ThirtyThree"
                            aria-expanded="false"
                            aria-controls="ThirtyThree"
                            style={{ color: "#F9A738" }}
                          >
                            <p>Contact Us</p>
                          </button>
                        </h2>
                        <div
                          id="ThirtyThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Don't hesitate to contact us if you have any
                              questions.
                            </p>
                            <li>Via Email: hello@buzzler.in</li>
                            <li>Via Phone Number: (+91) 91547 91547</li>
                            <li>
                              Via this Address: T-Hub 2.0, 20, Inorbit Mall Rd,
                              Vittal Rao Nagar,Madhapur, Hyderabad, Telangana
                              500081
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : activeTab === "privacy policy" ? (
            <section>
            <div class="container">
              <div class="container mb-5">
                <h1 className="faq-text1">Privacy Policy</h1>
                <div class="service_background shadow mt-5">
                    <div class="accordion-container">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:'#F9A738'}}>
                                    <p>Introduction</p>
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul> <li><p>
                                        We Buzzler Technologies Pvt Ltd take your privacy very seriously and are committed to protecting your personal data (meaning any information about you from which you can be identified).</p></li>
                                        <li><p> Buzzler Technologies Pvt Ltd is the data controller who is responsible for your personal data (collectively referred to as “we”, “us” or “our”) in this privacy statement. If you do not agree to the processing of your personal data in the way this Privacy Statement describes, please do not provide your personal data. </p></li>
                                        <li><p>This Privacy Statement explains what personal data we collect when you access our website at www.balert.in or engage with us on social media platforms (including our Facebook, Twitter, YouTube, Instagram pages), and/or when you otherwise interact or communicate with us. It also explains how we collect, store or use this data, and what rights you have in relation to such data.</p></li>
                                        <li><p>Buzzler Technologies Pvt Ltd provides an enhanced tool wrapped with WhatsApp Web that allows us to store, manipulate, analyze and transfer messages between us and customer Personal/Business WhatsApp.</p></li>
                                        <li><p>We keep our privacy practices under review and may change this Privacy Statement from time to time by posting changes on the services or otherwise by notifying you. You are also responsible for periodically reviewing any changes which may be made to the Privacy Statement.</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{color:'#F9A738'}}>
                                    <p>Collection of Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We may collect information or pieces of information that could allow you to be identified, including:
                                    </p>
                                    <p> A) Information you provide us directly:</p>
                                    When you register as a user for the online Services (through a paid subscription or trial offer) and/or use the services you provide us with certain information that we store. This may include:
                                    <ol type="a">
                                        <li>
                                            <p>Personal identifiers (such as your name, username, social media name, address, email address, password, and phone number)</p>
                                        </li>
                                        <li>
                                            <p>Device identifiers (such as your carrier identifier)</p>
                                        </li>
                                        <li>
                                            <p>Personal characteristics (such as your date of birth, gender, and the language you speak)</p>
                                        </li>
                                        <li>
                                            <p>Financial information (such as payment card information and UPI address any other)</p>
                                        </li>
                                        <li>
                                            <p>Audio and visual information (such as account photo, other photos, and other materials)</p>
                                        </li>
                                        <li>
                                            <p>Geolocation data and other metadata (such as content creation date, formatting information, and location information (I.e., geotags))</p>
                                        </li>
                                        <li>
                                            <p>Inferences from any of the above categories about your preferences.</p>
                                        </li>
                                    </ol>
                                    <p>B) Information collected from third parties</p>
                                    <ul>

                                        <p>We may receive information about you from third parties who may have collected and transferred your personal data to us in accordance with their own privacy policies and/or terms of use. This may include personal identifiers (such as your name, address, email address, and phone number) and personal characteristics (such as	 your date of birth and gender). Examples of such third-party includes social media networks.</p>
                                        <p>When we receive such information from such third parties, we may combine it with the other personal data that we have collected about you as set out herein and use such information either alone or in combination with the other personal data we hold for the purposes outlined in this Privacy Statement.</p>
                                    </ul>
                                    <p>C)Information we get when you use our services:</p>
                                    <ul>
                                        <p>We collect the technical data automatically as you interact with our services by using cookies and other similar web technologies. When you access our websites or use our WhatsApp enabled chat system, we, our service providers and our business partners may automatically collect information about you, your computer or mobile device, and activity on our websites or mobile applications. Typically, this information includes your computer or mobile operating system, type and version number, manufacturer and model, device identifier (such as Google advertising ID or Apple ID for advertising), browser type, screen resolution, IP address, the website you visited before browsing to our website, general location information such as city, state or geographic area and information about your use of and actions on or in our websites such as pages or screens you accessed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access time and length of access.
                                        </p>
                                        <p>Not all of the lists above will necessarily apply to you – it depends on your use of the services and your particular interaction and communications with us.
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={{color:'#F9A738'}}>
                                    <p> Usage of Information Collected From You</p>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>The information collected from you may be used to operate, provide, develop and improve our services. Data like demographic and identity data online identifiers personal data and other technical data is used to manage orders, provide services, deliver products, process payments and communicate with you and to improvise on all our products and services. Here’s what we use your information for:</p>

                                    <ul>
                                        <li>send you technical notices updates, security alerts and support and administrative messages;</li>
                                        <li>verify your identity and provide you access to our services;</li>
                                        <li>respond to your comments questions request and provide customer service;</li>
                                        <li>communicate with you about products, services to provide news and information we think will be of interest to you;
                                        </li>
                                        <li>monitor and analyze trends, usage and activities in connection with our services;</li>
                                        <li>revise, modify, enhance and update existing services and develop new services;</li>
                                        <li>detect, investigate and prevent fraudulent transactions and other illegal activities;</li>
                                        <li>personalize the services and present you with advertising contain or features that we believe will be of interest or useful to you;</li>
                                        <li>link or combine with information we get from others to help us understand your needs and provide you with better services;</li>
                                        <li>enforce our terms of service and other policies; and </li>
                                        <li>carry out any other purpose describe to you at the time the data was collected.</li>
                                    </ul><br />
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style={{color:'#F9A738'}}>
                                    <p> Sharing Your Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p> We may share your personal data with:</p>
                                    <ol type="a">
                                        <li>
                                            <p>Any member of our Group. The term “Group” shall mean any entity that is controlled by us or any entity that is in control of us or any entity that is under common control with us, whether directly or indirectly.</p>
                                            <p>our employees, vendors, agents and professional advisors working on our behalf for the purposes described in this policy statement; and</p>
                                            <p>service-providers who assist in protecting and securing our systems and provide services to us which require the processing of personal data, such as to host your information or to process your information for data profiling and user analysis.</p>
                                        </li>
                                    </ol>
                                    <p>We usually do not share other personal Data collected from the Website with other third parties. However, this may happen if:</p>
                                    <ol type="a">
                                        <li>
                                            <p> You request or authorize us to do so;</p>
                                            <p> We need to comply with applicable law or respond to valid legal process; or</p>
                                            <p>We need to operate and maintain the security of this website, including to prevent or stop an attack on our computer systems or networks.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" style={{color:'#F9A738'}}>
                                    <p> Use of Cookies And Other Trackers</p>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We use temporary and permanent cookies, tags, scripts, and other similar technologies to identify users of our services and to enhance user experience, identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for tracking your activities on our websites. We use third party tracking services like “Google Analytics” and (any other) to understand the behavior of our visitors and serve them better. You can set your browser not to accept cookies. However, in a few cases, some of our website features may not function as a result. Please refer to our Cookie Policy for more information.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" style={{color:'#F9A738'}}>
                                    <p> Links to Third Party Websites</p>
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Our website may provide links to third party websites for your information. If you access those links through our website, you will leave our website. The owners and operators of these third-party websites are responsible for their collection and/or use of your personal data and you should check their respective privacy policies before submitting your personal information unless specifically referred to otherwise, this Privacy Policy does not apply to any third-party websites.</p>
                                    <p>WE HEREBY DISCLAIM LIABILITY FOR ANY INFORMATION, MATERIALS, PRODUCTS, OR SERVICES POSTED OR OFFERED AT ANY OF THE THIRD-PARTY SITES LINKED TO THIS WEBSITE. BY CREATING A LINK TO A THIRD-PARTY WEBSITE, WE DO NOT ENDORSE OR RECOMMEND ANY PRODUCTS OR SERVICES OFFERED OR THE INFORMATION CONTAINED AT THAT WEBSITE NOR ARE WE LIABLE FOR ANY FAILURE OF PRODUCT OR SERVICES OFFERED OR ADVERTISED AT THOSE SITES. SUCH THIRD-PARTY MAY HAVE A PRIVACY POLICY DIFFERENT FROM THAT OF OURS AND THE THIRD-PARTY WEBSITE MAY PROVIDE LESS SECURITY THAN THIS SITE.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" style={{color:'#F9A738'}}>
                                    <p> Security of Your Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>We’re committed to protecting our users’ personal data. We implement appropriate technical and organizational measures to help protect the security of your personal data. However, be aware that no system is ever completely secure.</p>
                                    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, altered, disclosed, or accessed in an unauthorized manner. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instruction, and they are subject to a duty of confidentiality. In relation to third–party service providers whom we appoint to process your personal data on our behalf we take steps to ensure that those service providers are contractually bound to protect your personal data.</p>
                                    <p>We have put in place procedures to deal with any suspected data security bridge and will notify you and any applicable regulator where we are legally required to do so.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight" style={{color:'#F9A738'}}>
                                    <p> Retention of Personal Data</p>
                                </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Buzzler Technologies retains personal data for as long as necessary to provide the access to and use of the website, or for other essential purposes such as complying with our legal obligations, resolving disputes and enforcing our agreements. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.</p>
                                    <p>Even if we delete your data, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.</p>
                                    <p>In general terms, we will retain your personal data for the duration of your involvement/ engagement with us and for as long as reasonably necessary afterwards. However, we may maintain different retention periods for different products and services. There are also certain types of information that are required to be retained for a certain period by law. If you close or request that we close your account, we’ll delete or anonymize your personal data so that it no longer identifies you, unless we’re required to keep something, or we still need to use it for a legally justifiable reason.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine" style={{color:'#F9A738'}}>
                                    <p> Your Individual Legal Rights</p>
                                </button>
                            </h2>
                            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Our company would like to make sure you are fully aware of all of your data protection rights.  Below, you will find additional privacy information that you may find important. Our company adheres to applicable data protection laws and the Information Technology Act, 1500, as amended or substituted, which if applicable includes the following rights in relation to your Data:</p>
                                    <ol type="a">
                                        <li>
                                            <p> Right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                                            <p>Right to rectification - You have the right to request to correct any information you believe is inaccurate. You also have the right to request complete information you believe is incomplete.</p>
                                            <p>Right to erasure- You have the right to request to erase your personal data, under certain conditions.</p>
                                            <p>Right to restriction - You have the right to request to restrict the processing of your personal data, under certain conditions.</p>
                                            <p>Right to data portability - You have the right to request to transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                                            <p>Right to object - You have the right to object to processing your personal data, under certain conditions.</p>
                                        </li>
                                    </ol>
                                    <p>It is important that the data we hold about you is accurate and current. Please keep us informed if your data changes during the period for which we hold it. Requests should be made in writing, and we may ask you to verify your identity along with your request. If you make a request, we have one month to respond to you. We will not charge your fee to exercise your rights unless your request is clearly unfounded or excessive, in which case we may charge you a reasonable fee. Alternatively, we may refuse to comply with the request in such circumstances.</p>
                                    <p> If you would like to exercise any of these rights, please contact us at our email:</p>
                                    <p>Call us at:</p>
                                    <p>Or write to us:</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen" style={{color:'#F9A738'}}>
                                    <p> Contact Us</p>
                                </button>
                            </h2>
                            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>Please contact Buzzler Technologies if:</p>
                                    <ol type="a">
                                    <li>
                                        <p> you have any questions about this Privacy statement, our practices relating to the website;</p>
                                        <p> you wish to exercise one of your data protection rights; or</p>
                                        <p> you otherwise have a question or complaint about the manner in which the data we hold on to you.</p>
                                    </li>
                                    </ol>
                                    <p>You can reach us by emailing: hello@buzzler.in</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        ) : activeTab === "cancellation and Refund Policy" ? (
            <section>
            <div class="container">
              <div class="container mb-5">
                <h1 className="faq-text1">Cancellation and Refund Policy</h1>
                <div class="service_background shadow mt-5">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <p style={{color:'#F9A738'}}>Definitions and key terms</p>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>
                                    To help explain things as clearly as possible in this Return & Refund Policy, every
                                    time any of
                                    these terms
                                    are
                                    referenced, are strictly defined as:
                                <ul>
                                    <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to
                                        balert,
                                        that is
                                        responsible for your information under this Return & Refund Policy.</li>
                                    <li>Customer: refers to the company, organization or person that signs up to use the
                                        balert
                                        Service to
                                        manage the relationships with your consumers or service users.</li>
                                    <li>Device: any internet connected device such as a phone, tablet, computer or any
                                        other device
                                        that can
                                        be
                                        used to visit balert and use the services.</li>
                                    <li>Service: refers to the service provided by balert as described in the relative
                                        terms (if
                                        available)
                                        and
                                        on this platform.</li>
                                    <li>Website: balert’s site, which can be accessed via this URL: www.balert.in</li>
                                    <li>You: a person or entity that is registered with balert to use the Services.</li>
                                </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <p style={{color:'#F9A738'}}>Cancellation Policy</p>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <h5>
                                    Order Cancellation by the Customer:
                                </h5><br/>
                                <p>Customers can request the cancellation of their order before the payment process is
                                    completed. To
                                    cancel
                                    an
                                    order, the customer must contact our customer support team through the provided
                                    channels on our
                                    website.
                                    Once the payment process is completed, the order cannot be canceled.
                                </p><br/>
                                <h5>
                                    Order Cancellation by BUZZLER TECHNOLOGIES PRIVATE LIMITED:
                                </h5><br/>
                                <p>BUZZLER TECHNOLOGIES PRIVATE LIMITED reserves the right to cancel an order in the
                                    following
                                    circumstances:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Insufficient information provided by the customer during the order process.
                                        </p>
                                    </li>

                                    <li>
                                        <p>Payment authorization is not received or declined.</p>
                                    </li>
                                    <li>
                                        <p>fraudulent activity related to the transaction.</p>
                                    </li>
                                    <li>
                                        <p>Technical issues or errors during the payment process.</p>
                                    </li>
                                    <li>
                                        <p>Any other valid reason at the discretion of BUZZLER TECHNOLOGIES PRIVATE
                                            LIMITED.</p>
                                    </li>
                                    <li>
                                        <p>In the event of an order cancellation by BUZZLER TECHNOLOGIES PRIVATE
                                            LIMITED, customers
                                            will be
                                            notified promptly, and any payment made for the canceled order will be
                                            refunded
                                            according to the
                                            Refund Policy outlined in Section 2.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <p style={{color:'#F9A738'}}>Refund Policy</p>
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <h5>
                                    Eligibility for Refunds:
                                </h5><br/>
                                <ul>
                                    <li>Refunds will be considered in the following cases:</li>
                                    <li>Order cancellation by the customer before payment completion.</li>
                                    <li>Failed transactions due to technical errors or payment gateway issues.</li>
                                    <li>Order cancellation by BUZZLER TECHNOLOGIES PRIVATE LIMITED (as outlined in
                                        Section 1.2).
                                    </li>
                                </ul><br/>
                                <h5>
                                    Refund Process:
                                </h5><br/>
                                <ul>
                                    <li>To request a refund, customers must contact our customer support team through
                                        the provided
                                        channels
                                        on
                                        our website. The refund request will be processed within 7 days of receiving the
                                        request.
                                        The refund
                                        will be issued using the original payment method used by the customer during the
                                        order
                                        process.
                                    </li>
                                </ul>
                                <br/>
                                <h5>
                                    Refund Amount:
                                </h5><br/>
                                <ul>

                                    <li>In the case of order cancellation by the customer before payment completion, the
                                        full order
                                        amount
                                        will
                                        be refunded.</li>
                                    <li>For failed transactions due to technical errors or payment gateway issues, the
                                        full order
                                        amount
                                        will be
                                        refunded.</li>
                                    <li>If BUZZLER TECHNOLOGIES PRIVATE LIMITED cancels the order for any valid reason,
                                        the full
                                        order
                                        amount
                                        will be refunded.</li>

                                </ul>
                                <br/>
                                <h5>
                                    Non-Refundable Products/Services:
                                </h5><br/>
                                <ul>
                                    <li>Certain products or services offered by BUZZLER TECHNOLOGIES PRIVATE LIMITED may
                                        be
                                        non-refundable.
                                        Customers will be clearly notified of such cases before making the payment.</li>
                                </ul>
                                <br/>
                                <h5>
                                    Refund Timeframe:
                                </h5><br/>
                                <ul>
                                    <li>The time required to process the refund and for the refunded amount to reflect
                                        in the
                                        customer's
                                        account
                                        may vary depending on the payment method and the customer's bank. Generally,
                                        refunds are
                                        processed
                                        within 48 hours, but it may take additional time for the refund to appear in the
                                        customer's
                                        account.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <p style={{color:'#F9A738'}}>Your Consent</p>
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>By using our website, registering an account, or making a purchase, you hereby
                                    consent to our
                                    Return &
                                    Refund
                                    Policy and agree to its terms.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-right' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <p style={{color:'#F9A738'}}>Changes To Our Return & Refund Policy</p>
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>Should we update, amend or make any changes to this document so that they accurately reflect our
                                    Service
                                    and
                                    policies. Unless otherwise required by law, those changes will be prominently posted here. Then,
                                    if you
                                    continue
                                    to use the Service, you will be bound by the updated Return & Refund Policy. If you do not want
                                    to agree
                                    to
                                    this
                                    or any updated Return & Refund Policy, you can delete your account.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" data-aos='fade-left' data-aos-duration='1000'>
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                <p style={{color:'#F9A738'}}>Contact Us</p>
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p>If, for any reason, You are not completely satisfied with any service that we provide, don't
                                    hesitate to
                                    contact
                                    us and we will discuss any of the issues you are going through with our product.</p>
                                <ul>
                                    <li>Via Email: hello@buzzler.in</li>
                                    <li>Via Phone Number: (+91) 91547 91547</li>
                                    <li>Via this Address: T-Hub 2.0, 20, Inorbit Mall Rd, Vittal Rao Nagar,Madhapur, Hyderabad, Telangana 500081</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) :null}
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
